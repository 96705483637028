import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LogoBoard } from './components/';
import './App.css';

function App() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    // Due to SSR/SSG we can not set 'App onMobile' or 'App onDesktop' on the server
    // If we modify className using JS, we will got Warning: Prop `className` did not match. Server: "App" Client: "App onDesktop"
    // So we have to apply document.body.class using the hook :)
    if (isMobile) {
      document.body.classList.remove('onDesktop');
      document.body.classList.add('onMobile');
    } else {
      document.body.classList.remove('onMobile');
      document.body.classList.add('onDesktop');
    }
  }, [isMobile]);

  return (
    <div className="App">
      <header className="header">
        <LogoBoard className="light font1" />
      </header>
      <main className="content">
        <p>We are software engineering and art design company founded on October 7, 2020 in Bratislava, Slovakia</p>
        <p>
          Our founders are software engineer <a href="https://github.com/karpolan">Anton Karpenko</a> and art dealer{' '}
          <a href="https://muzaolga.com/">Olga Muzychenko</a>. Company director is{' '}
          <a href="https://www.linkedin.com/in/karpolan/">Anton Karpenko</a>.
        </p>
        <p>
          Official IDs:
          <p className="center margin-top margin-bottom">
            Name: ANTOLA s.r.o.
            <br />
            IČO: 53230035
            <br />
            DIČ: 2121339924
            <br />
            VAT: SK2121339924
          </p>
        </p>
        <p>
          Official Address:
          <address className="center margin-top margin-bottom">
            ANTOLA s.r.o.
            <br />
            Doležalova 15C
            <br />
            Bratislava
            <br />
            82104
            <br />
            SLOVAKIA
          </address>
        </p>
        <p>
          If you need website, landing page, mobile application, design materials or IT consulting, feel free to contact
          us via email:{' '}
          <a href="mailto:hello@antola.sk?subject=Mail from Website" target="_blank" rel="noreferrer noopener">
            hello@antola.sk
          </a>
        </p>
      </main>
      <footer className="footer">
        Copyright &copy; 2020-{currentYear} <a href="/">ANTOLA s.r.o.</a>
      </footer>
    </div>
  );
}

export default App;
