import { useState } from 'react';
import clsx from 'clsx';
import { useInterval } from '../../utils/hooks';
import './LogoBoard.css';

const WORDS = [
  [
    'analytics',
    'art',
    // 'artificial intelligence',
    'artificial',
    'ai',
    'api',
    'actions',
    'applications',
    'algorithms',
    'advertisement',
  ],
  ['negotiation', 'nlp', 'native applications', 'neural networks', 'network', 'nosql', 'nfc', 'naming'],
  ['technologies', 'tech', 'troubleshooting', 'traffic', 'telecommunications', 'tools', 'translations', 'token'],
  ['optimization', 'outsourcing', 'online', 'object', 'open source', 'oop', 'observer', 'offers'],
  ['learning', 'lead', 'leading', 'lan', 'logic', 'live streaming', 'location', 'legal'],
];

const defaultWords = [WORDS[0][0], WORDS[1][0], WORDS[2][0], WORDS[3][0], WORDS[4][0], WORDS[0][1]];

function getRandomWords() {
  const a = WORDS[0][Math.trunc(Math.random() * WORDS[0].length)];
  const n = WORDS[1][Math.trunc(Math.random() * WORDS[1].length)];
  const t = WORDS[2][Math.trunc(Math.random() * WORDS[2].length)];
  const o = WORDS[3][Math.trunc(Math.random() * WORDS[3].length)];
  const l = WORDS[4][Math.trunc(Math.random() * WORDS[4].length)];
  const z = WORDS[0][Math.trunc(Math.random() * WORDS[0].length)];
  return [a, n, t, o, l, z];
}

/**
 * Renders randomly changed Texts starting with A,N,T,O,L,A chars
 */
const LogoBoard = ({ className }) => {
  const [words, setWords] = useState(defaultWords);

  useInterval(() => {
    setWords(getRandomWords());
  }, 1000);

  return (
    <div className={clsx('LogoBoard', className)}>
      <div className="row">
        <div className="cell fixed">A</div>
        <div className="cell random">{words[0].substring(1)}</div>
      </div>
      <div className="row">
        <div className="cell fixed">N</div>
        <div className="cell random">{words[1].substring(1)}</div>
      </div>
      <div className="row">
        <div className="cell fixed">T</div>
        <div className="cell random">{words[2].substring(1)}</div>
      </div>
      <div className="row">
        <div className="cell fixed">O</div>
        <div className="cell random">{words[3].substring(1)}</div>
      </div>
      <div className="row">
        <div className="cell fixed">L</div>
        <div className="cell random">{words[4].substring(1)}</div>
      </div>
      <div className="row">
        <div className="cell fixed">A</div>
        <div className="cell random">{words[5].substring(1)}</div>
      </div>
    </div>
  );
};

export default LogoBoard;
