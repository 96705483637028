import { useRef, useEffect } from 'react';

/**
 * Hook to execute Callback periodically with specific Interval
 * @param {function} callback - function to execute
 * @param {number} interval - number of milliseconds between Callback executions
 */
export const useInterval = (callback, interval) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (interval !== null) {
      let id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [interval]);
};
